import React from "react";
import { Header } from "./App";

export function OmOss() {
  return (
    <><Header />
    <div className="min-h-screen flex flex-col items-center justify-center bg-stone-50 p-6">
      <div className="max-w-3xl text-center">
        <h1 className="text-3xl sm:text-5xl font-bold text-gray-900 mb-6">
          Om oss
        </h1>
        <p className="text-gray-700 text-lg sm:text-xl leading-relaxed">
          
          Vi är ett företag som sedan 2004 erbjuder städ och flyttjänster över hela Stockholm. 
          <strong>Crystal Clean Kos</strong> startade för <strong>20 år </strong> sedan med ett tydligt mål: att leverera högkvalitativa 
          tjänster med dedikerad personal, till konkurrenskraftiga priser. Med utgångspunkt från <strong>Haninge </strong> 
          är vi ett pålitligt städbolag som täcker hela Stockholm och strävar efter att erbjuda den bästa 
          servicen för både företag och privatpersoner.
        </p>
        

        <p className="text-gray-700 text-lg sm:text-xl leading-relaxed mt-4">
        Med korrekt utstrustning inför varje uppdrag prioriterar hygien och renlighet i högsta grad och ser till att varje del av ditt hem är fläckfritt. På Crystal Clean Kos tar vi hand om städningen så att du kan ägna dig åt det som verkligen betyder något för dig.

Vårt dedikerade team strävar efter att överträffa dina förväntningar genom ett brett utbud av städ och flyttjänster. Med vårt personliga tillvägagångssätt och vår noggranna personal ser vi till att ditt hem inte bara blir rent, utan också en plats där du kan slappna av och trivas. Låt oss ta hand om städningen, medan du njuter av de fläckfria resultaten!
        </p>
      </div>
    </div>
    </>
  );
}
