import "./App.css";
import { cn } from "@/lib/utils";
import { Marquee } from "@/components/marquee";
import { ShimmerButton } from "@/components/shimmer-button";
import { BoxReveal } from "@/components/box-reveal";
import React, { JSX } from "react";
import { BlurFade } from "@/components/magicui/blur-fade";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Link,
} from "react-router-dom";
import Kontakt from "./kontakt";
import { OmOss } from "./omoss";

function App() {
  return (
    <Router>
      <Routes>
        {/* Home Page */}
        <Route path="/" element={<Home />} />

        {/* Contact Page */}
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/om-oss" element={<OmOss />} />
      </Routes>
    </Router>
  );
}

export default App;

// Home Component
function Home() {
  return (
    <div className="App">
      <Header />
      <BoxRevealDemo />
      <div className="flex flex-col items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:w-2/3 lg:h-2/3 lg:items-center lg:justify-items-center">
          <FlyttCard />
          <BortforslingCard />
          <TrappCard />
          <KontorCard />
          <HemCard />
          <FlyttstadCard />
          <VisningCard />
          <ByggCard />
          <TradgardCard />
          <WindowCard />
        </div>
      </div>
      <MarqueeDemo />
      <Footer />
    </div>
  );
}

const reviews = [
  {
    name: "Erik",
    username: "@erik",
    body: "Flyttstädningen var perfekt! Fick tillbaka hela depositionen.",
    img: "https://avatar.vercel.sh/erik",
  },
  {
    name: "Lina",
    username: "@lina",
    body: "Hemstädningen var över förväntan! Kommer boka igen.",
    img: "https://avatar.vercel.sh/lina",
  },
  {
    name: "Johan",
    username: "@johan",
    body: "Fönsterputsningen gjorde verkligen skillnad. Proffsigt utfört!",
    img: "https://avatar.vercel.sh/johan",
  },
  {
    name: "Sofia",
    username: "@sofia",
    body: "Storstädat och skinande rent! Kan varmt rekommendera dem.",
    img: "https://avatar.vercel.sh/sofia",
  },
  {
    name: "Emma",
    username: "@emma",
    body: "Bästa städfirman jag testat! Noggrant och effektivt.",
    img: "https://avatar.vercel.sh/emma",
  },
  {
    name: "Anders",
    username: "@anders",
    body: "Kontoret har aldrig varit fräschare. Grymt jobb!",
    img: "https://avatar.vercel.sh/anders",
  },
];

const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
}) => {
  return (
    <figure
      className={cn(
        "relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
        // dark styles
        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]"
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium dark:text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  );
};

export function Stats() {
  return (
    <div className="stats shadow flex flex-row flex-wrap justify-center gap-5 sm:gap-4 p-2 sm:p-4 divide-none">
      <div className="stat w-24 sm:w-auto flex flex-col items-center text-center border-none">
        <div className="stat-title text-xs sm:text-sm">Erfarenhet</div>
        <div className="stat-value text-primary text-4xl">
          20+ <span className="text-lg">år</span>
        </div>
      </div>

      <div className="stat w-24 sm:w-auto flex flex-col items-center text-center ">
        <div className="stat-title text-xs sm:text-sm">Nöjda kunder</div>
        <div className="stat-value text-secondary text-4xl">320+</div>
      </div>

      <div className="stat w-24 sm:w-auto flex flex-col items-center text-center border-none">
        <div className="stat-value text-4xl">1200+</div>
        <div className="stat-title text-xs sm:text-sm">Projekt avslutade</div>
      </div>
    </div>
  );
}

export function Footer() {
  return (
    <footer className="footer footer-center bg-primary text-primary-content p-10">
      <aside>
        {/* Replace SVG with your logo */}
        <img
          src="/logo.png" // Replace with the actual path to your logo
          alt="Crystal Clean Kos Logo"
          className="h-12 w-auto" // Adjust height as needed
        />
      </aside>
      <nav>
        <h6 className="footer-title">Kontakta oss nu!</h6>
        <Link to="/kontakt" className="link link-hover">
          Kontakt
        </Link>
        <Link to="/om-oss" className="link link-hover">
          Om oss
        </Link>
        <Link to="/tjanster" className="link link-hover">
          Våra tjänster
        </Link>
      </nav>
      <p>Copyright © {new Date().getFullYear()} - All rights reserved</p>
    </footer>
  );
}

export function Header() {
  return (
    <div className="navbar bg-base-100 flex justify-between items-center px-4">
      {/* Hamburger menu - aligned left */}
      <div className="flex-none">
        <div className="dropdown">
          <button tabIndex={0} className="btn btn-square btn-ghost">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block h-5 w-5 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
          {/* DROPDOWN MENU */}
          <ul
            tabIndex={0}
            className="menu dropdown-content p-2 shadow bg-base-100 rounded-box w-52 mt-3 z-50"
          >
            <li>
              <Link to="/" className="block w-full p-2">
                Hem
              </Link>
            </li>
            <li>
              <Link to="/kontakt" className="block w-full p-2">
                Kontakt
              </Link>
            </li>
            <li>
              <Link to="/om-oss" className="block w-full p-2">
                Om oss
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Navbar Logo - Centered */}
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <Link to="/">
          <img
            src="/logo.png" // Replace with your actual logo path
            alt="Crystal Clean Kos Logo"
            className="h-10 w-auto" // Adjust height as needed
          />
        </Link>
      </div>
    </div>
  );
}

export function MarqueeDemo() {
  return (
    <div className="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden border bg-stone-50 md:shadow-xl">
      <Marquee pauseOnHover className="[--duration:20s]">
        {firstRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <Marquee reverse pauseOnHover className="[--duration:20s]">
        {secondRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
    </div>
  );
}

export function HemCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="hemstäd.jpg" alt="Hemstädning" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-lg">
            Hemstädning / Storstädning
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Crystal Clean Kos gör vardagen enklare för våra kunder. Med lång
              erfarenhet och noggrant fokus på hög kvalitet tar vårt team hand
              om alla uppgifter i hemmet, vi tar även stor hänsyn till känsliga
              områden som golv och ömtåliga möbler.
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}

export function TradgardCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="tradgard.jpg" alt="Hemstädning" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-2xl">
            Trädgårdsarbete
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Vi erbjuder professionellt trädgårdsarbete som hjälper dig att
              hålla din trädgård i toppform året runt. Vårt erfarna team tar
              hand om alla typer av trädgårdsuppgifter, stora som små. Vi
              erbjuder bland annat klippning av häckar och gräs samt röjning och
              krattning.
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}

export function FlyttCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="flytt.jpg" alt="Hemstädning" className="" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-2xl">
            Flytt
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Vi erbjuder pålitliga och effektiva flyttjänster för både privata
              och företagsflyttar. Vi hanterar hela processen – från packning
              och lastning till transport och uppackning – med hög kvalitet och
              stor hänsyn till dina ägodelar. Med oss kan du känna dig trygg att
              flytten går snabbt och säkert.
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}

export function VisningCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="visning.jpg" alt="Hemstädning" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-2xl">
            Visningsstäd
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Vi har lång erfarenhet av visningsstädning för bostadsföreningar
              och fastighetsägare. Vår noggranna och pålitliga service
              säkerställer att allt är i ordning inför visningen, med fokus på
              hög kvalitet och effektivitet för en bekymmersfri upplevelse för
              både kunden och potentiella köpare.
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}

export function KontorCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="kontor.jpg" alt="Hemstädning" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-2xl">
            Kontorsstädning
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Vår företagsstädning kännetecknas av bekymmersfri service med
              högsta kvalitet. Vår personal trivs väl med våra kunder och
              motiveras av att leverera bästa möjliga resultat. Vi erbjuder både
              engångsstädning och regelbundna avtalsstädningar.
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}

export function ByggCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="bygg.jpg" alt="Byggstädning" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-2xl">
            Byggstädning
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Vi erbjuder även byggstädning av alla storlekar oavsett om det
              gäller dig som privatperson eller företag.
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}

export function FlyttstadCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="flyttstad.jpeg" alt="Flyttstädning" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-2xl">
            Flyttstädning
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Vårt arbetssätt för flyttstädning gör att vi kan utnyttja tiden
              maximalt. Vi använder alltid rätt utrustning för att säkerställa
              ett förstklassigt resultat.
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}

export function BortforslingCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="bortforsling.jpg" alt="Flyttstädning" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-2xl">
            Bortforsling
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Vi erbjuder pålitlig och effektiv bortforsling av skräp, gamla
              möbler och oönskade föremål för både privatpersoner och företag.
              Med hög professionalitet och noggrant utförande tar vi hand om
              allt från hämtning till korrekt avfallshantering, så att du kan
              fokusera på annat.
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}

export function TrappCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="trapp.jpg" alt="Flyttstädning" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-xl">
            Trappstädning/Fastighetsstäd
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Vi har i många år städat hos flera olika föreningar och städningen
              kännetecknas av bekymmersfri service och hög kvalitet. Vi erbjuder
              både engångsstädning och regelbundna avtalsstädningar.
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}

export function WindowCard() {
  const navigate = useNavigate(); // Initialize the navigation function
  return (
    <div className="card glass w-100 lg:w-2/3 lg:h-2/3">
      <figure>
        <img src="window.webp" alt="Fönsterputsning" />
      </figure>
      <div className="card-body bg-stone-50">
        <div className="bg-stone-50 collapse collapse-arrow">
          <input type="checkbox" className="peer" />
          <div className="collapse-title bg-stone-50 text-black font-bold peer-checked:bg-stone-50 peer-checked:text-black font-abhaya text-2xl">
            Fönsterputsning
          </div>
          <div className="collapse-content bg-stone-50 text-black peer-checked:bg-stone-50 peer-checked:text-black">
            <p>
              Fönsterputsning ger ett välskött intryck i alla miljöer, både i
              hemmet och på företaget! Vi har lång erfarenhet av fönsterputs och
              ser alltid till att lämna dina fönster skinande rena!
            </p>
          </div>
        </div>

        <div className="card-actions justify-end">
          <button
            className="btn bg-softbeige"
            onClick={() => navigate("/kontakt")}
          >
            Begär offert!
          </button>
        </div>
      </div>
    </div>
  );
}
// <BlurFade delay={0.35} inView>

export function BoxRevealDemo() {
  return (
    <div className="relative w-full">
      {/* Fixed Background Image */}
      <div
        className="top-0 left-0 w-full h-screen bg-center opacity-80 transition-opacity duration-500
                   fixed lg:absolute"
        style={{ backgroundImage: "url('/fram.jpg')", zIndex: -1 }}
      ></div>

      {/* Hero Section */}
      <div className="relative w-full flex items-center justify-center text-white text-center px-6 py-64">
        <BoxReveal boxColor={"#5046e6"} duration={0.5}>
          <p className="text-[1.5rem] sm:text-[2.5rem] font-semibold font-abhaya">
            Välkommen till Crystal Clean Kos!
          </p>
        </BoxReveal>
      </div>

      {/* Content Section that scrolls over the image */}
      <div className="relative z-10 bg-white py-16 px-6 sm:px-12 text-center">
        <p className="text-gray-600 text-lg sm:text-xl max-w-2xl mx-auto">
          Sedan 2004 är vi ett företag som erbjuder städ och flyttjänster över
          hela Stockholm. Crystal Clean Kos startade för 20 år sedan med ett
          tydligt mål: att leverera högkvalitativa tjänster med dedikerad
          personal, till konkurrenskraftiga priser. Med utgångspunkt från
          Haninge är vi ett pålitligt städbolag som täcker hela Stockholm och
          strävar efter att erbjuda den bästa servicen för både företag och
          privatpersoner.
        </p>

        <Stats />
      </div>
    </div>
  );
}
