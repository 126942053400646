import { Header } from "./App";
import { useState } from "react";
import emailjs from "@emailjs/browser";

export function Kontakt() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs.send(
      "service_fntnzbe", // Replace with your EmailJS Service ID
      "template_ys4sfwi", // Replace with your EmailJS Template ID
      formData,
      "7nukSX8SKpCVKp5VH" // Replace with your EmailJS Public Key
    )
    .then(() => {
      setStatus("✅ Meddelandet har skickats!");
      setFormData({ name: "", email: "", message: "" }); // Reset form
    })
    .catch(() => setStatus("❌ Något gick fel, försök igen."));
  };

  return (
    <>
      <Header />
      <div className="max-w-3xl mx-auto p-6 mt-16">
        <h1 className="text-3xl font-bold mb-6 text-center">Kontakt</h1>
        <p className="text-black mb-8 text-center">
          Vi vill gärna höra från dig! Fyll i formuläret nedan eller kontakta oss direkt på{' '}
          <a href="mailto:crystalcleankos@hotmail.com" className="text-blue-600 hover:text-blue-800">
            crystalcleankos@hotmail.com
          </a>.
        </p>

        <form className="space-y-6" onSubmit={sendEmail}>
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-black">Namn</label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Ditt namn"
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-black">E-post</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="you@example.com"
              required
            />
          </div>

          <div>
            <label htmlFor="message" className="block text-sm font-medium text-black">Meddelande</label>
            <textarea
              id="message"
              rows={4}
              value={formData.message}
              onChange={handleChange}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Ditt meddelande"
              required
            />
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="inline-block px-6 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Skicka meddelande
            </button>
          </div>
        </form>

        {status && <p className="text-center mt-4 font-semibold">{status}</p>}

        
      </div>
    </>
  );
}

export default Kontakt;
