import ReactDOM from 'react-dom/client'
import App from "./App";
import './index.css';
import React from 'react';


ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)


